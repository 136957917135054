<template>
  <div class="about-history">
    <h2 align="center" class="animate__animated animate__bounceInRight">
      Historical evolution
    </h2>
    <p align="center" class="animate__animated animate__bounceInRight">
      2008年成立至今
    </p>

    <!-- <div class="his-img">
      <img class="banner" src="../../assets/image/路径 29041.png">
    </div> -->
    <div class="banner">
      <a-timeline mode="alternate" class="hietori-timeline">
        <a-timeline-item class="hietori-timeline-item">
          <template>
            <div>
              <h3>2023</h3>
              <div>
                <ul>
                  <li>上海达译取得电子与智能化工程专业承包一级资质</li>
                  <li>武汉达译取得ISO27001认证</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item color="green" class="hietori-timeline-item">
          <template>
            <div>
              <h3>2022</h3>
              <div>
                <ul>
                  <li>青岛达译成立</li>
                  <li>武汉达译入选东西湖区“瞪羚企业”</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>

        <a-timeline-item color="red" class="hietori-timeline-item">
          <template>
            <div>
              <h3>2021</h3>
              <div>
                <ul>
                  <li>陕西达译成立</li>
                  <li>武汉达译获得“科技型中小企业”认证</li>
                  <li>陕西达译取得电子与智能化工程专业承包二级资质</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item class="hietori-timeline-item">
          <template>
            <div>
              <h3>2020</h3>
              <div>
                <ul>
                  <li>武汉达译获得“高新技术企业”认证</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item color="green" class="hietori-timeline-item">
          <template>
            <div>
              <h3>2019</h3>
              <div>
                <ul>
                  <li>武汉达译获得“软件企业”认证</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item color="red" class="hietori-timeline-item">
          <template>
            <div>
              <h3>2018</h3>
              <div>
                <ul>
                  <li>武汉达译成立</li>
                  <li>上海达译取得建筑机电安装工程专业承包三级资质</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item class="hietori-timeline-item">
          <template>
            <div>
              <h3>2014</h3>
              <div>
                <ul>
                  <li>上海达译取得ISO9001质量管理体系认证</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
        <a-timeline-item color="green" class="hietori-timeline-item">
          <template>
            <div>
              <h3>2013</h3>
              <div>
                <ul>
                  <li>上海达译成立</li>
                </ul>
              </div>
            </div>
          </template>
        </a-timeline-item>
      </a-timeline>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hisList: [
        {
          id: 1,
          hisDate: "2023",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "上海达译取得电子与智能化工程专业承包一级资质",
          hisTwo: "武汉达译取得ISO27001认证",
        },
        {
          id: 2,
          hisDate: "2022",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "青岛达译成立",
          hisTwo: "武汉达译入选东西湖区“瞪羚企业”",
        },
        {
          id: 3,
          hisDate: "2021",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "陕西达译成立",
          hisTwo: "武汉达译获得“科技型中小企业”认证",
          hisThree: "陕西达译取得电子与智能化工程专业承包二级资质",
        },
        {
          id: 4,
          hisDate: "2020",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "武汉达译获得“高新技术企业”认证",
        },
        {
          id: 4,
          hisDate: "2019",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "武汉达译获得“软件企业”认证",
        },
        {
          id: 4,
          hisDate: "2018",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "武汉达译成立",
          hisTwo: "上海达译取得建筑机电安装工程专业承包三级资质",
        },
        {
          id: 4,
          hisDate: "2014",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "上海达译取得ISO9001质量管理体系认证",
        },
        {
          id: 4,
          hisDate: "2013",
          hisIcon: require("../../assets/image/151.png"),
          hisOne: "上海达译成立",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.about-history {
  padding: 80px 260px;
  margin-bottom: 100px;
  position: relative;

  h2 {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }

  p {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 44px;
    color: #666666;
  }
}

@media all and (max-width: 700px) {
  .about-history {
    padding: 80px 260px;
    margin-bottom: 100px;
    position: relative;

    h2 {
      font-size: 80px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }

    p {
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 52px;
      color: #666666;
    }

    .banner {
      .hietori-timeline {
        .hietori-timeline-item {
          // .ant-timeline-item-tail {
            
          // }
          // .ant-timeline-item-head {
          //   width: 36px;
          //   height: 36px;
          // }

          .ant-timeline-item-content {
            
            h3 {
              font-size: 60px;
            }
            ul {
              li {
                font-size: 48px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
